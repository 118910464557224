<template>
  <div v-if="entriesChecked.length > 1 && this.currentActivity.inputs === undefined && isActivity && !reseted" class="info-container">
    <p class="text-reset">{{ $t("activities.multiple.edit.inputs") }}</p>
    <RewriteButton :on-click="resetAllInputs"/>
  </div>

  <div v-else>
    <!-- Inputs table -->
    <div class="mb-2" v-for="input in allInputsToPatch()" v-bind:key="input.inputsStock">

      <!-- input stock infos -->
      <b-row class="m-0" no-gutters v-if="!showIfSemis">
        <b-col
          cols="5"
          class="text-infos bg-white d-flex align-items-start py-2 pl-2 pr-1"
          @change="checkUnits(input)"
        >
          <s v-if="!isInputInInputform(input)"> {{ input.inputsStock_type + " - " + input.inputsStock_name }} </s>
          <span v-else> {{ input.inputsStock_type + " - " + input.inputsStock_name }} </span>
        </b-col>
        <b-col cols="7" class="text-infos bg-white d-flex align-items-start pr-2">
          <div
            v-if="input.errQuantity"
            class="text-activity-notes error-quantity-inputs"
          >{{ input.errMessage }}</div>
          <NumericInput
            ref="focused"
            inputRef="input"
            name="input-stock"
            :value="parseFloat(input.dose)"
            @input="dose => { onInputDoseChanged(input, dose); }"
            placeholder="Dose"
            :required="isActivity && entriesChecked.length > 1 ? false : true "
            :disabled="tourHasStarted && !isDuplicateMode"
            :min="0"
            :max="getDoseMax(input)"
            :warningText="$t('components.EditInputs.warningDose') + getDoseMax(input)"
            :numberOfDigits="4"
          />
          <b-select
            size="sm"
            :options="input.doseUnit"
            text-field="unit"
            value-field="id"
            :value="input.doseUnit[0].id"
            v-model="input.unit"
            @input="onInputChanged(input)"
            :disabled="tourHasStarted && !isDuplicateMode"
            class="ml-1"
            required
          ></b-select>
          <button type="button" v-if="!tourHasStarted || isDuplicateMode" class="btn align-bottom ml-1 pr-0" @click="onDelete(input.inputsStock)">
            <img class="delete_medium" :src="icon" alt="icon delete action" />
          </button>
        </b-col>
      </b-row>

      <!-- Input stock infos -->
      <b-row class="m-0" no-gutters v-if="showIfSemis">
        <b-col
          cols="7"
          class="text-infos bg-white d-flex align-items-centre py-2 pl-2 pr-1"
          @change="checkUnits(input)"
        >
          <s v-if="!isInputInInputform(input)"> {{ input.inputsStock_type + " - " + input.inputsStock_name }} </s>
          <span v-else> {{ input.inputsStock_type + " - " + input.inputsStock_name }} </span>
        </b-col>
        <b-col v-if="!tourHasStarted || isDuplicateMode" cols="5" class="text-infos bg-white d-flex flex-row-reverse pr-4">
          <button type="button" class="btn align-bottom ml-1 pr-0" @click="onDelete(input.inputsStock)">
                <img class="delete_medium" :src="icon" alt="icon delete action" />
          </button>
        </b-col>
      </b-row>
      <!-- Input Dose -->
      <b-row class="m-0" no-gutters v-if="showIfSemis">
        <b-col
          cols="5"
          class=" text-infos bg-white d-flex flex-row-reverse py-2 pl-2 pr-3"
        >
        <h4 class="subtitle-form"> {{ $t("components.EditInputs.dose") }} </h4>
        </b-col>
        <b-col cols="7" class="text-infos bg-white d-flex align-items-start pr-2" v-b-tooltip.hover.html="input.dose === null && entriesChecked.length > 1 ? 'Les Doses des activités sont calculer de valeur proportionelle quand vous etes en selectionmultiple et que vous avez pas choisi de dose unitaire' : null">
          <div
            v-if="input.errQuantity"
            class="text-activity-notes error-quantity-inputs"
          >{{ input.errMessage }}</div>
          <NumericInput
            ref="focused"
            inputRef="input"
            name="input-stock"
            :value="parseFloat(input.dose)"
            @input="dose => { onInputDoseChanged(input, dose) }"
            :required="isActivity && entriesChecked.length > 1 ? false : true "
            :max="getDoseMax(input)"
            :warningState="input.dose > getDoseMax(input)"
            :numberOfDigits="4"
          />
          <b-select
            size="sm"
            :options="filterDoseUnits(input.doseUnit)"
            text-field="unit"
            value-field="id"
            :value="input.doseUnit[0].id"
            v-model="input.unit"
            @input="onSeedUnitChanged(input.unit, input)"
            class="ml-1"
            required
          ></b-select>
        </b-col>
      </b-row>

      <!-- Input Density -->
      <b-row class="m-0" no-gutters v-if=" input.seedDensityUnit != null && showIfSemis">
        <b-col
          cols="5"
          class="text-infos bg-white d-flex flex-row-reverse py-2 pl-2 pr-3"
        >
          <h4 class="subtitle-form">{{ $t("components.EditInputs.seedDensity") }} </h4>
        </b-col>

        <b-col cols="7" class="text-infos bg-white d-flex align-items-start pr-2">
          <div
            v-if="input.errQuantity"
            class="text-activity-notes error-quantity-inputs"
          >{{ input.errMessage }}</div>
          <NumericInput
            ref="focused"
            inputRef="input"
            name="input-stock"
            :value="parseFloat(input.seedDensity)"
            @input="dose => {input.seedDensity = dose}"
            :required="true"
          />
          <b-input
            size="sm"
            :value="input.seedDensityUnit_unit"
            class="ml-1"
            disabled
          ></b-input>
        </b-col>
      </b-row>

      <!-- Input Treatment -->
      <div v-for="(treatment, index) in input.treatments" v-bind:key="treatment.refProduct">
        <b-row class="m-0" no-gutters >
          <b-col
            cols="5"
            class="text-infos bg-white d-flex flex-row-reverse pl-2 pr-3"
            >
            <h4 class="subtitle-form">{{ index == 0 ? $t("components.EditInputs.seedTreatment") : "" }}</h4>
          </b-col>
          <b-col
          cols="5" class="text-infos bg-white d-flex align-items-center pr-4"
          >
            <span>{{ treatment.refProduct_name }}</span>
          </b-col>
          <b-col cols="2" class="text-infos bg-white d-flex flex-row-reverse pr-4">
            <button class="btn align-start ml-1 pr-0" @click="deleteInputTreatment(input, treatment)">
              <img class="delete_small" :src="icon" alt="icon delete action" />
            </button>
          </b-col>
        </b-row>
      </div>

      <!--  show treatments selection if the seed is not treated  -->
      <b-row class="m-0" no-gutters v-if="showIfSemis && !input.inputsStock_treated && input.productFamily_code === 'sem'">
        <b-col
          cols="5"
          class="text-infos bg-white d-flex flex-row-reverse py-2 pl-2 pr-3"
        >
          <h4 class="subtitle-form">{{ input.treatments.length == 0 ? $t("components.EditInputs.seedTreatment") : "" }}</h4>
        </b-col>
        <b-col cols="7" class="text-infos bg-white pr-2" >
          <Dropdown
            ref="treatmentDropdown"
            class="shape-custom-select"
            :placeholder="$t('inputStock.details.placeholder')"
            :options="treatmentOptions[input.inputsStock]"
            label="name"
            @input="selectedTreatment => addTreatment(selectedTreatment, input)"
            :clearSelection="true"
            :clearOnLoad="true"
            :clearOnSelect="true"
          />
        </b-col>
      </b-row>

      <!-- input stock volumes details -->
      <b-row class="px-2 m-0 br-top-0 bg-grey text-infos" v-if="showVolumes && totalSurface" no-gutters>
        <b-col v-if="isActivity" class="text-dark-grey pt-2 m-0">{{ $t("components.Inputs.volume-used") }}</b-col>
        <b-col v-else class="text-dark-grey pt-2 m-0">{{ $t("components.Inputs.volume-to-use") }}</b-col>
        <b-col cols="4" class="text-right pt-2 mr-1" v-if="isActivity || isGenericTour">({{ fixDigits(input.dose, 5) }} x {{ totalSurface }}) = </b-col>
        <b-col cols="3">
          <NumericInput
            inputRef="input"
            :value="totalVolume(input)"
            :disabled="tourHasStarted && !isDuplicateMode"
            textHover="components.Inputs.total_quantity_text_hover"
            :align_right="true"
            @blur="vol => {onTotalQuantityInputChanged(input, vol);}"
            :numberOfDigits="3"
          />
        </b-col>
        <b-col cols="1" class="text-right pt-2">
          {{ getRelatedDoseUnit_unit(input.doseUnit.find(d => d.id == input.unit).id) }}
        </b-col>
      </b-row>
    </div>

    <!-- input stock mixture volume -->
    <div class="bg-grey text-infos mb-2 br-1" v-if="showMixtureVolumes">
      <b-row class="px-0 m-0 mb-2" align-v="center" no-gutters>
        <b-col cols="7" class="text-dark-grey">{{ $t("components.Inputs.mixture-volume") }}</b-col>
        <b-col cols="3" class="text-right d-flex align-items-start">
          <NumericInput
            inputRef="input"
            :value="parseFloat(mixtureVolume)"
            :disabled="tourHasStarted && !isDuplicateMode"
            @input="i => {mixtureVolume = i}"
            :numberOfDigits="2"
            :required="true"
          />
        </b-col>
        <b-col cols="2" class="text-right">
          {{ getDoseReferenceUnit().unit }}
        </b-col>
      </b-row>
      <b-row class="px-0 m-0" align-v="center" no-gutters>
        <b-col cols="6" class="text-dark-grey">{{ $t("components.Inputs.total-volume") }}</b-col>
        <b-col cols="6" class="text-right font-weight-bold" v-if="isGenericMixtureVolume">({{ mixtureVolume }} x {{ totalSurface }}) = {{ totalMixtureVolume }} {{ getRelatedDoseUnit_unit(getDoseReferenceUnit().id) }}</b-col>
        <b-col cols="6" class="text-right font-weight-bold" v-else>{{ totalMixtureVolume }} {{ getRelatedDoseUnit_unit(getDoseReferenceUnit().id) }}</b-col>
      </b-row>
    </div>

    <!-- Inputs select list -->
    <Dropdown
      ref="dropdown"
      class="shape-custom-select mt-2"
      label="designation"
      :placeholder="$t('components.EditInputs.placeholder')"
      :options="getInputsUpdated"
      @input="selectedInput => addInputs(selectedInput)"
      :clearSelection="true"
      :clearOnLoad="true"
      :clearOnSelect="true"
      :disabled="tourHasStarted && !isDuplicateMode"
    />
    <DialogModal />
  </div>
</template>

<script>
import _ from "lodash";
import delIcon from "@/assets/images/delete.svg";
import ActivityModule from "@/store/modules/activity";
import { SET_STATE_INPUT_TO_PATCH } from "@/store/modules/activity";
import { mapGetters } from "vuex";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import Dropdown from "@/components/activity/edit/Dropdown.vue";
import LoadFormPartMixin from "@/mixins/LoadFormPartMixin";
import NumericInput from "@/components/commons/NumericInput.vue";
import { EventBus } from "@/eventBus.js";
import RewriteButton from "@/components/commons/RewriteButton.vue";
import DialogModal from "@/components/commons/DialogModal.vue";
import { fixDigits } from "@/utils/commons";
import { isEmpty } from "lodash";

export default {
  name: "edit-inputs",
  mixins: [DetailUpdateMixin, LoadFormPartMixin],
  props: {
    useForm: {
      type: String,
      require: true,
    },
    inputFormPath: {
      type: String
    },
    showVolumes: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputsToPatchClone: [],
      inputsUpdated: [],
      page: this.useForm,
      icon: delIcon,
      inputSelected: "",
      inputsToPatch: [],
      units: [],
      selectedDoseUnit: {},
      loadComponent: false,
      mixtureVolume: 0,
      mixtureThresholdVolume: 50,
      treatmentOptions: {},
      reseted: false,
    };
  },
  components: {
    Dropdown,
    NumericInput,
    RewriteButton,
    DialogModal
  },
  async beforeDestroy(){
   await this.$store.dispatch(this.storeEditAction, { inputs: [] });
  },
  created() {
    this.init();
  },
  methods: {
    round: _.round,
    fixDigits: fixDigits,
    init: async function() {
      //if (Object.keys(this.currentActivity) == 0) return;
      // Si on modifie une activité on initialise le tableau d'intrant
      // à partir de l'activité actuel
      this.loadComponent = false;
      this.$store.commit("activity/" + SET_STATE_INPUT_TO_PATCH, false);

      if (["activity.edit", "tour.edit", "tour.duplicate"].includes(this.$route.name)) {

        (this.currentEditedEntry.inputs?.length > 0 ? this.currentEditedEntry.inputs : this.currentEntry?.inputs)?.map(async input => {

          // Load treatmentOptions
          if(!input.inputsStock_treated && this.showIfSemis) await this.getTreatments(input, false);
          let doses = this.getInputAttribut(input.inputsStock_name, "doseUnit", "inputName");
          let filteredDoses = doses.filter(e => {
            if(e.id == input.doseUnit) return e;
          })

          if(filteredDoses.length == 0) doses.push({
            id: input.doseUnit,
            unit: input.doseUnit_unit
          })

          this.inputsToPatch.push({
            inputsStock: input.inputsStock,
            filledQuantity: input.filledQuantity || null,
            dose: input.dose,
            doseUnit: doses,
            unit: input.doseUnit,
            errQuantity: false,
            errMessage: "",
            inputsStock_name: input.inputsStock_name,
            inputsStock_type: input.inputsStock_type,
            productFamily_code: input.productFamily_code,
            seedDensity: input.seedDensity,
            seedDensityUnit: input.seedDensityUnit,
            seedDensityUnit_unit: input.seedDensityUnit_unit,
            localProduct: input.localProduct,
            treatments: [...input.treatments],
            inputsStock_treated: input.inputsStock_treated,
            usedQuantity: input.filledQuantity ? true : false
          });
        });
      }
      this.loadComponent = true;

      this.mixtureVolume = this.currentEditedEntry.mixtureVolume || this.currentEntry.mixtureVolume || 0

    },

    /**
     * Permet de vérifier si un intrant est dans le formulaire inputForm
     */
    isInputInInputform(input) {
      return this.inputForm.inputsStock?.filter(e => e.id == input.inputsStock).length != 0;
    },

    /* Propagation des changements liés à la dose
    */
    onInputDoseChanged(input, newDose) {
      input.dose = newDose;
      const coefToQuantityUnit = this.getCoefToQuantityUnit(input);
      input.filledQuantity = (newDose * this.totalSurface * coefToQuantityUnit);
      input.usedQuantity = false;
      this.onInputChanged(input);
    },

    /**
     * Recalcul de la dose générique et propagation de la modification sur les doses spécifiques
     */
    onTotalQuantityInputChanged(input, newVolume) {
      if (!newVolume) {
        if (input.filledQuantity > 0) {
          input.filledQuantity = 0;
          input.dose = 0;
        }
        return
      }
      let ratio = null;
      let newDose = 0;
      input.usedQuantity = true
      const coefToQuantityUnit = this.getCoefToQuantityUnit(input);

      if(this.isActivity) {
        if (input.dose === undefined){
          // Les doses étaient spécfiques à chaque parcelle, on demande ce qu'il faut faire
          let message = this.$t("activities.multiple.edit.filledQuantity")
          let okTitle = this.$t("activities.multiple.edit.doseIsProportional")
          let cancelTitle = this.$t("activities.multiple.edit.doseIsUniform")
          EventBus.$emit("showAlertModal", {
              message: message,
              okBtn: () => this.doseIsProportional(input, newVolume, coefToQuantityUnit),
              cancelBtn: () => this.doseIsUniform(input, newVolume, coefToQuantityUnit),
              okTitle: okTitle,
              cancelTitle: cancelTitle
            });
        }
        else {
          this.doseIsUniform(input, newVolume, coefToQuantityUnit);
        }
      }
      else {
        if (input.dose) {
          ratio = newVolume/this.totalVolume(input);
          newDose = fixDigits(input.dose * ratio, 4);
        } else {
          newDose = fixDigits(newVolume/this.totalSurface, 4);
        }

        input.filledQuantity = newVolume;
        input.dose = newDose;
        this.onInputChanged(input, ratio);
        }
    },

    /**
     * Delete inputs into inputsToPatch.
     */
    onDelete(id) {
      let idInput = id;
      let val = this.inputsToPatch.filter(function(elem) {
        if (elem.inputsStock == idInput) return elem;
      });

      const filter = this.inputsToPatch.indexOf(val[0]);
      if (filter > -1) this.inputsToPatch.splice(filter, 1);

      EventBus.$emit("onInputDeleted", id);

      // Delete the corresponding treatmentOptions
      delete this.treatmentOptions[id];
    },

    async onInputChanged(input, ratio = null) {
      EventBus.$emit("onInputChanged", input, ratio);
    },

    /**
     * Add inputs into the array 'inputsToPatch'.
     */
    async addInputs(value) {
      if(this.inputsToPatch.find(i => i.inputsStock == value)) {
        return
      }
      if(this.showIfSemis && !value.inputsStock_treated) {
        await this.getTreatments(value, true);
      }

      this.inputSelected = value.id;
      let newInput;

      if (this.inputSelected != "") {
        let id = this.inputSelected;
        let input = this.inputForm.inputsStock.filter(function(elem) {
          if (elem.id == id) {
            return elem;
          }
        });
        this.tmp = input;

        if (!this.checkIfInputWasAdded(input[0].id)) {
          newInput = {
            inputsStock: input[0].id,
            filledQuantity: null,
            dose: null,
            doseUnit: input[0].doseUnit,
            unit: input[0].doseUnit[0].id,
            errQuantity: false,
            errMessage: "",
            inputsStock_name: input[0].inputName,
            inputsStock_type: input[0].category,
            productFamily_code: input[0].productFamily_code,
            seedDensity: input[0].seedDensity,
            seedDensityUnit: input[0].seedDensityUnit,
            seedDensityUnit_unit: input[0].seedDensityUnit_unit,
            treatments: [],
            localProduct: input[0].localProduct ? input[0].localProduct : false,
            inputsStock_treated: input[0].inputsStock_treated,
            usedQuantity: false
          }
          this.inputsToPatch.push(newInput);
        }
      }

      /**
       * Focus on the selected input
       */
      if (this.inputSelected) {
        this.$nextTick(() => {
          if(!Array.isArray(this.$refs.focused)) {
            this.$refs.focused.$refs.input.focus();
          } else {
            this.$refs.focused[this.$refs.focused.length - 1].$refs.input.focus();
          }
        });
      }
      this.inputSelected = "";

      // synchronize with store
      this.$nextTick(() => {
        this.$store.dispatch(this.storeEditAction, {
          inputs: this.inputsToPatchClone
        });

        EventBus.$emit("onInputAdded", _.clone(newInput))
      })

    },

    /**
     * Description: Confirme le chargement des données de ce composant.
     * @return {Boolean} Component loaded.
     */
    validateForm: function() {
      this.isValid = true;
      return this.loadComponent;
    },

    /**
     * Description: Renvoie true si un input a déjà été ajouté sinon false.
     * @param {number}      id L'id de l'input.
     * @return {boolean}
     */
    checkIfInputWasAdded(id) {
      let response = this.inputsToPatch.filter(function(elem) {
        if (elem.inputsStock == id) return elem;
      });

      if (response.length > 0) return true;
      return false;
    },

    /**
     * Description: Renvoie l'attribut d'un input selon le nom de l'attribut de comparaison renseigné
     * (l'ID par defaut) et la valeur de celui-ci.
     * @param {}            attrValue La valeur de l'attribut de l'input que l'on connaît.
     * @param {String}      attrResearched Le nom de l'attribut que l'on recherche.
     * @param {String}      attrCmp Le nom de l'attribut par lequel comparer la value de attrValue
     *                          (l'ID par défaut).
     * @return {}           La valeur de retour dépend de l'attribut recherché.
     */
    getInputAttribut(attrValue, attrResearched, attrCmp = "id") {
      let input = this.inputForm.inputsStock?.filter(function(elem) {
        if (elem[attrCmp] == attrValue) return elem;
      });
      if(!input || typeof input[0] === 'undefined') return [];
      return input[0][attrResearched];
    },

    /**
     * Renvoie tout les inputs à patcher.
     * Vérifie si il y a eu un changement de type d'activité et s'il faut donc
     * vider le contenu du tableau 'inputsToPatch'.
     */
    allInputsToPatch() {
      if (this.inputsMustToBeClean) {
        this.inputsToPatch = [];
        this.$store.commit("activity/" + SET_STATE_INPUT_TO_PATCH, false);
      }
      return this.inputsToPatch;
    },
    checkUnits(input) {
      this.units = getInputAttribut(input.inputsStock, "doseUnit");
    },
    getCoefToQuantityUnit(input) {
      const doseUnit = input.doseUnit ? input.doseUnit.find(d => d.id === input.unit) : null;
      const relaTedDoseUnit = this.getRelatedDoseUnit(doseUnit.id);
      const coef = doseUnit && relaTedDoseUnit ? doseUnit.coefToReferenceUnit / relaTedDoseUnit.coefToReference : 1;  // Coefficient à utiliser dans le calcul du total
      return coef
    },
    getRelatedDoseUnit_unit(unit_id) {
      return this.doseUnits.find(d => d.id == unit_id)?.relatedUnit_unit
    },
    getRelatedDoseUnit(unit_id) {
      const doseUnit = unit_id ? this.doseUnits.find(d => d.id == unit_id) : null;
      if (doseUnit == null) return null;
      return this.doseUnits.find(d => d.id == doseUnit.relatedUnit)
    },

    /**
     * Retourne l'unité de réference des doses des liquides
     */
    getDoseReferenceUnit() {
      let ref_dose = null;
      this.doseUnits.map(d =>{
        if(d.isReference == true && d.forDose == true && d.form == "L") {
          ref_dose = d;
        }
      });
      return ref_dose
    },
    /**
     * Filter dose units with forDose == true
     */
    filterDoseUnits(doseUnit){
      const filteredDoseUnits = doseUnit.filter(elem => elem.forDose == true);
      return filteredDoseUnits;
    },
    onSeedUnitChanged(value, input){
      // Mise à jour de l'unité de saisie de densité et propagation du changement vers les doses spécifiques
      const selectedDoseUnit = input.doseUnit.find(element => element.id == value);
      let relatedDoseUnit = {};

      if(selectedDoseUnit.relatedDensityUnit_id){
        relatedDoseUnit = input.doseUnit.find(element => element.id == selectedDoseUnit.relatedDensityUnit_id);
      }
      input.seedDensityUnit = relatedDoseUnit.id;
      input.seedDensityUnit_unit = relatedDoseUnit.unit;
      this.onInputChanged(input);
    },
    async getTreatments(input, fromInputForm){
      let inputStock = null;
      let productTypeId = null;
      // if the input is coming from the inputForm.inputsStock
      if(fromInputForm) {
        productTypeId = input.productType;
        inputStock = input.id;
      } else {
        // if the input is coming from inputsToPatch
        inputStock = input.inputsStock;
        productTypeId = this.inputForm.inputsStock.find(el => el.id === inputStock)?.productType;
      }
      // We get the related crop types id
      let cropTypes_id = this.inputForm.productTypes.find(pt => pt.id === productTypeId)?.cropTypes?.map(cropType => cropType.id);

      if(cropTypes_id && cropTypes_id.length > 0) {
        const data = await this.$store.dispatch("getSeedTreatments", cropTypes_id);
        this.treatmentOptions[inputStock] = [];
        this.treatmentOptions[inputStock].push(...data);
      }
    },
    addTreatment(selectedTreatment, input){
      // Iterate over input.treatments and add the selectedTreatment if not already present
      if(selectedTreatment != null){
        if(!input.treatments.find(t => t.refProduct == selectedTreatment.id))
          input.treatments.push({
            refProduct : selectedTreatment.id,
            refProduct_name : selectedTreatment.name
          });
      }
    },
    deleteInputTreatment(input, treatment){
      if(treatment != (null || undefined)){
        let indexToDelete = input.treatments.indexOf(treatment);
        if(indexToDelete > -1) input.treatments.splice(indexToDelete, 1);
      }
    },
    // Retourne la dose max d'un intrant sélectionné
    getDoseMax(input) {
      let doses = input.doseUnit;
      let selectedDose = doses.find(e => e.id == input.unit);
      return selectedDose?.max || 10000;
    },

    /**
     * inputs related to the cropfieldId
     */
    cropfieldInputs(cropfield_id) {
      const entryInputs = this.currentEntry.cropFields?.find(c => c.cropField == cropfield_id)?.inputs
      const editedEntryInputs = this.currentEditedEntry.cropFields?.find(c => c.cropField == cropfield_id)?.inputs

      return editedEntryInputs && editedEntryInputs.length > 0 && editedEntryInputs || entryInputs || []
    },

    /**
     * Détermine le volume total d'un intrant
     * Prend en compte les quantités spécifiques
     */
    totalVolume(input) {
      let somme = 0;
      let test_input;
      const coefToQuantityUnit = this.getCoefToQuantityUnit(input);

      if(!this.isActivity){
        this.cropfields.forEach(cf => {
          test_input = this.cropfieldInputs(cf.cropField).find(cf_input => cf_input.inputsStock == input.inputsStock);
          if (test_input ) {
            somme = somme + parseFloat(test_input.dose) * coefToQuantityUnit * parseFloat((cf.surfaceToWork || cf.cropField_surface));
          } else {
            somme = somme + parseFloat(input.dose) * coefToQuantityUnit * parseFloat((cf.surfaceToWork || cf.cropField_surface));
          }
        })
      } else {
        if(!input.usedQuantity) {
            somme = parseFloat(input.dose) * coefToQuantityUnit * parseFloat(this.totalSurface);
           } else {
            somme = parseFloat(input.filledQuantity)
           }
      }

      return fixDigits(somme, 5);
    },

    resetAllInputs(){
      this.reseted = true
      this.$store.dispatch(this.storeEditAction, { inputs: [] });
    },

    doseIsUniform(input, newVolume, coefToQuantityUnit) {
      input.filledQuantity = newVolume;
      input.dose = fixDigits(newVolume/this.totalSurface/coefToQuantityUnit, 5);
    },

    doseIsProportional(input, newVolume, coefToQuantityUnit) {
        input.filledQuantity = newVolume;
    },
  },
  beforeCreate() {
    const store = this.$store;
    if (store && store.state) {
      if (!store.state["activity"]) {
        store.registerModule("activity", ActivityModule);
      }
    }
  },
  computed: {
    ...mapGetters({
      currentActivity: "activity/currentEntry",
      inputsMustToBeClean: "activity/inputsMustToBeClean",
      doseUnits: "doseUnits",
      entriesChecked: "activity/entriesChecked"
    }),
    inputForm() {
      return this.$store.getters[this.inputFormPath]
    },
    tourHasStarted() {
      return this.$route.name.includes("tour") && this.currentEntry && this.currentEntry.status && this.currentEntry.status !== "NS"
    },
    isDuplicateMode() {
      return this.$route.name === "tour.duplicate"
    },
    isActivity(){
      return this.$route.name.includes("activity")
    },
    /**
     * Renvoie un array pris de this.inputForm.inputsStock + surchargé avec un attribut
     * category_inputName qui va être util dans le DropDown
     */
    getInputsUpdated() {
      this.inputsUpdated = [];
      if(this.inputForm.inputsStock != undefined)
      {
        this.inputForm.productTypes.map(productType => {
          this.inputsUpdated.push({
            id: productType.id + "group",
            designation: productType.designation,
            group: true,
          });
          this.inputForm.inputsStock.map(input => {
            if(input.productType === productType.id){
              this.inputsUpdated.push({
                ...input,
                designation: input.inputName
              });
            }
          });
        });
        // this.inputsUpdated = this.inputForm.inputsStock.map(obj => ({
        //   ...obj, category_inputName: obj.category + " - " + obj.inputName,
        //   }));
      }
      return this.inputsUpdated;
    },
    totalQuantity() {
      let somme = 0;
      this.allInputsToPatch().map(input =>{
        if (input.unit == this.getDoseReferenceUnit().id)
          somme = somme + parseFloat(input.dose);
      });
      return somme;
    },
    totalSurface() {
      if(this.isActivity){
        return parseFloat(this.currentEditedEntry.surface) || this.currentEntry.surface
      } else {
        return _.round((this.currentEditedEntry.cropFields || this.currentEntry.cropFields)?.map((c) => parseFloat((c.surfaceToWork || c.cropField_surface))).reduce((a, b) => a + b, 0), 2);
      }
    },
    /* when do we show mixture (bouillie) volumes ? */
    showMixtureVolumes() {
        return this.showVolumes // the showVolumes mode is activated
        && !this.isActivity
        && (this.currentEditedEntry.activityType_code || this.currentEntry.activityType_code) == "pulve" // we are in pulve family
        && this.allInputsToPatch().length > 0 // there are inputs
        && (this.totalQuantity < this.mixtureThresholdVolume) // we are below mixture threshold
    },
    showIfSemis(){
      return ((this.currentEditedEntry.activityType_code || this.currentEntry.activityType_code) &&
        ["semis", "plant", "combi"].includes(this.currentEditedEntry.activityType_code || this.currentEntry.activityType_code)
        );
    },

    /**
     * inputs by default, at the root of the entry
     */
    rootInputs() {
      const entryInputs = this.currentEntry.inputs
      const editedEntryInputs = this.currentEditedEntry.inputs

      return editedEntryInputs && editedEntryInputs.length > 0 && editedEntryInputs || entryInputs || []
    },
    cropfields() {
        return this.currentEditedEntry.cropFields || this.currentEntry.cropFields
    },

    /**
     * Permet de savoir si une tournée est générique ou non
     * Fais une boucle sur les intrants de chaque parcelle et vérifie s'il y en a qui ont des doses différentes de celles génériques
     */
    isGenericTour() {
        let isGeneric = true;
        let test_input = null;
        this.cropfields.forEach(cf => {
          this.cropfieldInputs(cf.cropField).forEach(cf_input => {
            test_input = this.rootInputs.find(input => input.inputsStock == cf_input.inputsStock && input.dose != cf_input.dose)
            if (test_input) {
              isGeneric = false;
            }
          })
        });
        return isGeneric;
    },
    /**
     * Retourne true si aucune n'a de volume de bouillie spécifique et false sinon
     */
    isGenericMixtureVolume() {
      let isGeneric = true;
      this.cropfields.forEach(cf => {
        if (cf.mixtureVolume && cf.mixtureVolume != this.mixtureVolume) {
          isGeneric = false;
        }
      });
      return isGeneric;
    },

    /**
     * Volume de mixture total
     * Prend en compte les volumes spécifiques des parcelles
     */
    totalMixtureVolume() {
      let somme = 0;

      this.cropfields.forEach(cf => {
        somme = somme + parseFloat(cf.mixtureVolume || this.mixtureVolume || 0) * parseFloat((cf.surfaceToWork || cf.cropField_surface));
      })

      return _.round(somme, 2);
    }
  },
  watch: {
    inputsToPatch: {
      deep: true,
      handler: async function() {
        this.inputsToPatchClone = JSON.parse(JSON.stringify(this.inputsToPatch));
        this.inputsToPatchClone.map(e=>{
          e.doseUnit = e.unit;
          e.dose = e.dose ? e.dose : undefined
          e.filledQuantity = e.usedQuantity ? e.filledQuantity : null
        });

        if (this.inputsToPatchClone.length != 0) await this.$store.dispatch(this.storeEditAction, { inputConfirmed: true });
        await this.$store.dispatch(this.storeEditAction, { inputs: this.inputsToPatchClone });
      },
    },
    async mixtureVolume(value) {
      // Provoque le changement des volumes de bouillie spécifiques aux parcelles de la tournée
      EventBus.$emit("onMixtureVolumeChanged", value);

      this.$store.dispatch(this.storeEditAction, {
        mixtureVolume: parseInt(value)
      });
    },
    inputForm: {
      deep: true,
      handler() {
        // Pour les activités d'irrigation, il ny'a que de l'eau proposée. Il faut donc directement la sélectionner
        if (this.inputForm.activityType_code === "irrig" && this.inputForm.inputsStock?.length === 1) {
          this.addInputs(this.inputForm.inputsStock[0]);
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
table {
  border-collapse: separate;
  border-spacing: 3px 3px;
  margin-bottom: 4px;
}

button {
  //   background-color: $danger-color;
  font-size: 0.8em;
  font-weight: bold;

  img.delete_medium {
    width: 1.5em;
  }
  img.delete_small {
      width: 1.3em;
  }
}

/**
 * Parameter error quantity inputs.
 */
.error-quantity-inputs {
  // text-align: center;
  color: $danger-color;
}

.form-control:disabled, .custom-select:disabled{
  background-color: $disabled-color;
}

.text-reset {
    font-size: 14px;
    padding: 5px;
    color: #222e50;
    margin: 5px;
}

.info-container {
  display: flex;
  align-items: center; /* Centre verticalement les éléments */
  justify-content: space-between; /* Place le texte à gauche et le bouton à droite */
}
</style>
